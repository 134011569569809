<template>
  <div>
    <bread-crumb :items="items"></bread-crumb>
    <v-container fluid>
      <v-img src="img/Hall.jpg">
        <template v-slot: card>
          <v-card
            class="mx-auto ma-16 pa-9 slide"
            rounded="xl"
            width="500"
            color="secondary"
          >
            <h1 class="primary--text text-center slide-text">FOR MORE ENQUIRIES</h1>
            <h3 class="warning--text text-center slide-text">
              CALL :
              <span class="primary--text text-decoration-underline">
                07039301287
              </span>
            </h3>
          </v-card>
        </template>
      </v-img>

      <h2 class="text-center mt-7">To Make More Enquries</h2>
      <v-row justify="center">
        <v-col cols="12" md="7" lg="8">
          <contact-form category="hall" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb.vue";
import ContactForm from "@/components/ContactForm.vue";
export default {
  name: "Hall",
  components: { BreadCrumb, ContactForm },

  data() {
    return {
      items: [
        {
          text: "Home",
          disabled: false,
          link: "Home",
        },
        {
          text: "Hall",
          disabled: true,
          link: "Hall",
        },
      ],
    };
  },
};
</script>
<style>

@media screen and (max-width: 600px) {
.slide{
width: 200px;
}

.slide-text{
font-size: 20px;
font-weight: 500;
}

}
@media screen and (min-width: 600px) {
.slide{
width: 550px;
}

.slide-text{
font-size: 30px;
font-weight: 700;
}

}

</style>
